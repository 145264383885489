<template>
  <div>
    <v-card flat max-width="600px" class="mx-auto mt-12 mb-8 text-center">
      <img class="error-img mb-8" src="../assets/undraw_warning_cyit.svg" />
    </v-card>
    <v-card max-width="600px" class="mx-auto px-6 py-4" elevation="6">
      <v-card-title class="text-h5 primary--text">
        对不起！您没有权限进行这个操作。
      </v-card-title>
      <v-card-text>
        如有需要，请联系管理员开通权限。
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" class="px-4" depressed @click="moveBack">
          返回
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  methods: {
    moveBack() {
      this.$router.back();
    }
  }
};
</script>

<style lang="scss" scoped>
.error-img {
  width: 60%;
}
</style>
